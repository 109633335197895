img.mw-400px {
  filter: brightness(105%);
}
.loginImage {
  filter: brightness(105%);
}

.AddNew .modal-body {
  overflow: visible !important;
}

.app .ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}

.app .ql-toolbar {
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  border-bottom: none;
}

.ql-editor {
  display: block;
  font-size: 15px;
}
.raw-editor {
  display: none;
  max-width: 100%;
  width: 100%;
  padding: 10px;
  border: 1px solid #cccc;
  border-top: none;
  font-size: 15px;
}
.showRaw .ql-editor {
  display: none;
}
.showRaw .raw-editor {
  display: block;
}

#toolbar {
  position: absolute;
  // top: 25%;
  left: 55%;
}

#toolbar button {
  padding: 10px;
  padding-top: 12px;
  border: none;
  background: none;
}

.svg-icon.svg-icon-2x.arrow svg {
  width: 25px !important;
  height: 25px !important;
  margin-bottom: 2px !important;
}

.arrow {
  padding-left: 10px !important;
}

.ql-toolbar .ql-snow {
  position: relative !important;
}

.customToolPtag {
  z-index: 50;
  // right: 40%;
}

.legalPtag {
  z-index: 50;
}

.customerPtag {
  z-index: 50;
}
.productsPtag {
  z-index: 50;
}

@media only screen and (min-width: 1350px) {
  .customToolPtag {
    // z-index: 111111;
    left: 70%;
  }
  .legalPtag {
    left: 80%;
  }
  .customerPtag {
    left: 50%;
  }
  .productsPtag {
    left: 80%;
  }
}
@media only screen and (min-width: 1300px) and (max-width: 1440px) {
  .customToolPtag {
    // z-index: 111111;
    left: 88% !important;
  }
  .legalPtag {
    left: 90% !important;
  }
  .customerPtag {
    left: 65%;
  }
  .productsPtag {
    left: 90%;
  }
}

@media only screen and (max-width: 1350px) {
  .customToolPtag {
    // z-index: 111111;
    left: 83%;
  }
  .legalPtag {
    left: 90%;
  }
  .customerPtag {
    left: 83%;
  }
  .productsPtag {
    left: 80%;
  }
}

.badge-social-primary {
  background-color: #c6e3fa;
}

.filterMenu .modal-body {
  overflow: scroll !important;
}

.existingBorder:hover {
  border-color: #009ef7 !important;
}

.disabled {
  pointer-events: none;
}

.hashTagMark mark {
  padding: 0 0.3rem;
  background-color: #c6e3fa !important;
  border-radius: 30px;
}
.input-box {
  display: flex;
  align-items: center;
  background: #eef3f7;
  border-radius: 4px;
  padding-left: 0.5rem;
  overflow: hidden;
}

.input-box .prefix {
  font-weight: 300;
  font-size: 14px;
  color: #999;
  // background: #f5f8fa;
}

.input-box input {
  flex-grow: 1;
  font-size: 14px;
  background: #fff;
  border: none;
  outline: none;
  padding: 0.5rem;
}

.input-box:focus-within {
  border-color: #777;
}
.rc-steps-item-icon {
  margin-right: 15px !important;
}

.rc-steps-vertical .rc-steps-item-content {
  min-height: 85px !important;
}

#generalSettingsDropdown-container {
  background-color: rgb(238, 243, 247);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media only screen and (min-width: 765px) {
  .search-closeIcon {
    top: 66px !important;
  }
}

.article-two-container {
  position: relative;
  // overflow: hidden;
  flex-shrink: 0;
  padding: 0 48px 0 0;
}

.article-two-container .img-container {
  position: relative;
  overflow: hidden;
  // height: 90%;
  // width: 90%;
  aspect-ratio: 16/9;
}
.article-two-container .image-responsive {
  height: -webkit-fill-available;
  overflow: hidden;
}
@media (max-width: 991px) {
  .article-two-container .img-container {
    width: 100%;
    height: 100%;
  }
}
.event-image .event-tag {
  position: absolute;
  top: 10px;
  left: 10px;
  border: 1px solid #0095e8;
  border-radius: 5px;
  background: #0095e8;
  color: var(--accent-textcolor);
  text-transform: uppercase;
  padding: 10px 15px;
  font-size: 12px;
  z-index: 100;
  color: white;
}
.microPage {
  // font-size: 15px;
  // background-color: rgba(50, 50, 50, 0.2);
  // color: white;
}

// .microPage,
// h4,
// h5,
// span {
//   color: white !important;
// }
.title_container {
  max-width: 750px;
  text-align: justify;
  text-align-last: left;
  margin: auto;
  margin-bottom: 24px !important;
}
title_container h1 {
  text-align: start;
}

.title_container h3 {
  color: gray;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  white-space: normal;
  line-height: 1.5rem;
  max-height: 1.5rem;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 1.5rem;
}
.title_container div {
  color: gray;
}
// div.event-description-one p:first-of-type:first-letter,
// div.event-description-two p:first-of-type:first-letter {
//   margin: 0.1em 0.2em 0 0;
//   font-size: 5em;
//   float: left;
//   font-weight: 600;
//   line-height: 0.8;
//   color: var(--accent-color);
// }
div.event-description-one,
div.event-description-two {
  text-align: justify;
  text-align-last: left;
  margin: auto;
  margin-top: 30px;
  p,
  ul,
  div,
  * {
    max-width: 750px;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    margin: 0 auto;
  }
  a {
    color: #009ef7;
  }
}
div.event-description-one {
  * {
    width: 100%;
  }
}

@media (max-width: 991px) {
  p.event-description {
    width: 100%;
  }
}

.event-image.rounded {
  width: 100% !important;
  object-fit: cover;
}

.event-icons {
  height: 30px;
  width: 30px;
  margin-right: 20px;
  background: #f8f7fa;
  padding: 5px;
  border-radius: 8px;
  color: #009ef7;
}

.leaflet-container {
  height: 300px;
  // width: 500px;
}

.map-message-wrapper {
  position: relative;
  width: 0px;
  height: 0px;
  justify-content: center;
  margin: 0 auto;
  display: flex;
}

.map-message {
  position: absolute;
  justify-content: center;
  display: flex;
  top: 150px;
  z-index: 500;
  width: 400px;
  font-weight: bold;
}

.map-blur-effect {
  filter: blur(5px);
}

.event-page-alignment {
  max-width: 750px;
  font-size: 18px;
  font-weight: 500;
  // line-height: 20px;
  // margin: auto !important;
}

@media (max-width: 1200px) {
  .event-page-alignment {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .location-top {
    padding-top: 20px;
  }
}

.map-loading-height {
  min-height: 300px;
}

@media (max-width: 575px) {
  div img.post-image,
  div.audio-wrapper div.img-container-two {
    height: 460px;
  }
}

div img.post-image {
  width: 100% !important;
  height: 460px;
  object-fit: cover;
}
@media (max-width: 575px) {
  // .video-container,
  div img.post-image {
    //height: 300px;
    height: auto !important;
    aspect-ratio: 16/9;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  video,
  .video-container,
  div img.post-image {
    //height: 400px;
    height: auto !important;
    aspect-ratio: 16/9;
  }
}

.title-wrapper .border-section {
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.title-wrapper .border-section::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 5px;
  z-index: 1;
}
.title-wrapper .border-section::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: #eee;
}
.title-wrapper h4 {
  text-transform: capitalize;
  font-size: 20px;
}

.map-toggle-button {
  border: none !important;
  padding: 0 !important;
  background: none;
  font-size: 1.2rem;
  color: #009ef7;
}

.microContainer .svg-icon svg [fill]:not(.permanent):not(g) {
  fill: #009ef7;
}

.article-content-description {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
  line-height: 1.5rem;
  height: 3rem;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 16px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .microContainer {
    max-width: 720px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .microContainer {
    max-width: 540px;
  }
}

@media (max-width: 576px) {
  .microContainer {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    margin-left: auto;
    margin-right: auto;
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .microContainer {
    max-width: 1140px !important;
  }
}

.contentModal {
  width: 1400px !important;
  // height: 700px !important;
}

// .contentModal .modal-body div:first-child {
//   max-height: 500px !important;
// }

.card .card-footer-padding {
  padding-bottom: 0px !important;
}

.EventProductModal .modal-body {
  max-height: none !important;
  overflow: hidden !important;
  flex: 1 1 auto !important;
}

// .DraftEditor-root {
//   min-height: 10em;
//   max-height: 15em;
//   overflow: scroll;
// }

table.custom-table {
  td {
    height: 30px;
    padding: 10px 0px 0px 0px;
  }
  tr td:nth-child(2) {
    text-align: right;
    padding-left: 10px;
  }
}
table.product-table {
  width: 100%;
  height: 60px;
  thead,
  tbody tr:first-child {
    padding-bottom: 12px;
    border-bottom: 2px solid black;
  }
  th,
  td {
    margin-top: 12px;
    padding: 10px 1rem;
    vertical-align: top;
  }
  th:first-child,
  td:first-child {
    padding-left: 0px;
  }
  tbody tr:not(tr:first-child) {
    height: 40px;
    td {
      vertical-align: middle;
    }
  }
  tbody tr {
    td:not(td:first-child) {
      border-bottom: 1px solid rgb(0, 0, 0, 0.5);
    }
  }
}

.quote {
  height: 70px;
  border-bottom: 2px solid rgb(0, 0, 0, 0.5);
}

.title-wrapper .border-section:before {
  background: #009ef7;
  bottom: 0;
  content: "";
  height: 5px;
  left: 0;
  position: absolute;
  width: 50px;
  z-index: 1;
}
.products-list-micro div:last-child {
  margin-bottom: 0px !important;
}

.ratingAddProfile {
  height: 50px;
  width: 50px;
  background: rgb(236, 229, 229);
  align-items: center;
  color: var(--accent-color);
  border-radius: 50%;
  margin: 0 10px 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}

.commentRatingSection .commentBody .commentFooter {
  padding: 10px 5px 10px 10px;
}

.selections_modal {
  overflow: hidden !important;
}

.post_comments_hr {
  margin: 20px 0;
  border-top: 1px solid gray;
}

.se-wrapper p {
  margin-bottom: 16px !important;
}

#assign .assignUserIcon svg {
  height: 30px !important;
  width: 30px !important;
}

.btn.btn-icon #assign .assignUserIcon {
  padding: 4px;
  border-radius: 50% !important;
}

.assignedCircle {
  border-radius: 50% !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.license_card_hover:hover {
  transition: transform 0.1s;
  transform: scale(1.02);
}

.license_card_hover_border:hover {
  border-color: #02b194 !important;
}
.license_card_border {
  border-color: #02b194 !important;
}

.license_card_name {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
#kt_aside_toggle.active {
  span {
    transform: rotate(360deg) !important;
  }
}
#kt_aside_toggle {
  span {
    transform: rotate(180deg) !important;
  }
}
.stepper.stepper-pills .stepper-item.current .stepper-icon .stepper-number {
  color: var(--accent-color) !important;
}
