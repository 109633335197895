html {
  font-family: sans-serif; // 1
  text-size-adjust: 100%; // 2
  -webkit-text-size-adjust: 100%; // 2
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-size: $root-font-size !important;
  font-weight: $font-weight-base;
  font-family: $font-family-sans-serif;
  overscroll-behavior-y: none;

  // Tablet mode
  @include media-breakpoint-down(lg) {
    font-size: $root-font-size-lg !important;
  }

  // Mobile mode
  @include media-breakpoint-down(md) {
    font-size: $root-font-size-md !important;
  }

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  a:hover,
  a:active,
  a:focus {
    text-decoration: none !important;
  }
}

body {
  display: flex;
  flex-direction: column;
  color: $body-color;
}

// Angular integration
router-outlet {
  display: none;
}

canvas {
  user-select: none;
}

.flex-even {
  flex: 1;
}
.small-size-modal {
  max-width: 500px !important;
}
.medium-size-modal {
  max-width: 650px !important;
}
.large-size-modal {
  max-width: 800px !important;
}
.extra-large-size-modal {
  max-width: 1000px !important;
}

.modal-body {
  max-height: calc(100vh - 280px) !important;
  overflow: scroll !important;
}

.set-modal-mh {
  max-height: calc(100vh - 500px);
  overflow: scroll;
}

.divider {
  height: 0.5px;
  background-color: #e0e0e0;
  margin: 40px 0px;
}

.divider-sm {
  height: 0.5px;
  background-color: #e0e0e0;
  margin: 32px 0px;
}

.divider-xsm {
  height: 0.5px;
  background-color: #e0e0e0;
  margin: 20px 0px;
}

.divider-xxsm {
  height: 0.5px;
  background-color: #e0e0e0;
  margin: 15px 0px;
}

.fs-13px {
  font-size: 13px !important;
}

.flex-1 {
  flex: 1;
}

.overflow-inherit {
  overflow: inherit !important;
}

.color-danger {
  color: #df4759 !important;
}

.spinner-border-size-2 {
  width: 1.4rem;
  height: 1.4rem;
  border-width: 0.2em;
}

.pointer-none {
  pointer-events: none;
}

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}
