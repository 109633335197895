.spinner-size-sm {
  width: 0.8rem !important;
  height: 0.8rem !important;
}
.spinner-size-md {
  width: 1rem !important;
  height: 1rem !important;
}

.spinner-centre {
  position: absolute;
  z-index: 1031;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}
